import React, { Component } from 'react';
import FormMaker from '../../../components/formMaker';
import { connect } from 'react-redux';
import { storeCurrentRequestId } from '../../../redux/actions';

export class WebIssues extends Component {
    render() {
        return (
            <div>
                <FormMaker formId="602da549ba5f15001bb52ff9" formType="web-issues" requiredTime={1}/>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    current_request_id: state.current_request_id
})

function mapDispatchToProps(dispatch) {
    return {
        storeCurrentRequestId: id => dispatch(storeCurrentRequestId(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebIssues)


